import { graphql } from 'gatsby';
import { Seo } from '../../components/Seo';
import ProjectList from '../../templates/project';

export default function Landelijk({ data, location }) {
  return <ProjectList data={data} location={location} type="landelijk" />;
}

export function Head() {
  return <Seo title="Onze landelijke projecten" />;
}

export const query = graphql`
  query {
    allFile(
      filter: { sourceInstanceName: { eq: "landelijk" } }
      sort: { fields: name, order: DESC }
    ) {
      nodes {
        id
        childImageSharp {
          thumbnail: gatsbyImageData(
            layout: CONSTRAINED
            width: 645
            placeholder: BLURRED
          )
          original: resize(width: 900) {
            src
          }
        }
      }
    }
  }
`;
