import { graphql } from 'gatsby';
import Container from '../components/Container';
import { Highlight } from '../components/Highlight';
import { Spacer } from '../components/Spacer';
import { Layout } from '../layout';
import { desktopOnly } from '../theme/utils.css';
import { imageGridColumnCss, imageGridCss } from './projectid.css';
import { Headline } from '../components/Headline';
import { Link } from '../components/Link';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Seo } from '../components/Seo';

export default function ProjectList({ data, location, type }) {
  return (
    <Layout location={location}>
      <Spacer y={10} />
      <Spacer y={10} className={desktopOnly} />

      <Container className={[imageGridCss, 'masonry']}>
        {data.allFile.nodes.map(({ id, childImageSharp }) => {
          return (
            <div className={imageGridColumnCss} key={id}>
              <a
                className="lightbox"
                href={childImageSharp.original.src}
                data-gall={type}
                target="_blank"
              >
                <GatsbyImage image={childImageSharp.thumbnail} alt="" />
              </a>
            </div>
          );
        })}
      </Container>
      <Spacer y={10} />
      <Spacer y={10} className={desktopOnly} />
      <Headline size="small" level={4} align="center">
        Jouw eigen project starten?{' '}
        <Highlight as={Link} to="/contacteer-ons" size="small">
          Neem contact op →
        </Highlight>
      </Headline>
    </Layout>
  );
}
